:root {
    --info: #5EA9BE !important;
}

html, body {
    width: 100%;
}

a, b, body, em, h1, h2, h3, h4, h5, h6, html, i, img, table, li, ol, p, strong, td, th, tr, ul, footer, pre {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-style: normal;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    font-family: Helvetica, "Apple Color Emoji", Arial, "PingFang TC", "Heiti TC", "Microsoft Jhenghei", sans-serif;
}

.card-body {
    font-size: 1rem;
}

.wsTit {
    font-size: 1rem;
}

.ezw1, .ezw1_1 {
    width: 80%;
}

.ezw3_1 {
    width: 70%;
}

/*custom btn*/
.btn-ezw-primary {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    font-weight: 600;
    color: white;
    background: var(--info);
    border-color: var(--info);
    border-radius: .5rem;
    border-width: 1px;
    border-style: solid;
    border-color: var(--info);
    outline: none;
}

    .btn-ezw-primary:hover, .btn-ezw-primary:focus, .btn-ezw-primary:focus-visible, .btn-ezw-primary:active {
        color: white;
        background: rgb(70,152,172);
        border-color: rgba(94,169,190, 0.1);
        box-shadow: 0 0 0px 4px rgb(94 169 190 / 10%);
        -webkit-box-shadow: 0 0 0px 4px rgb(94 169 190 / 10%);
        -moz-box-shadow: 0 0 0px 4px rgb(94 169 190 / 10%);
        -ms-box-shadow: 0 0 0px 4px rgb(94 169 190 / 10%);
        -o-box-shadow: 0 0 0px 4px rgb(94 169 190 / 10%);
    }

.btn-ezw-outline-primary {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    font-weight: 600;
    color: var(--info);
    background: transparent;
    border-color: var(--info);
    border-radius: .5rem;
    border-width: 1px;
    border-style: solid;
    border-color: var(--info);
    outline: none;
}

    .btn-ezw-outline-primary:hover, .btn-ezw-outline-primary:focus, .btn-ezw-outline-primary:focus-visible, .btn-ezw-outline-primary:active {
        color: white;
        background: var(--info);
        border-color: var(--info);
        box-shadow: 0 0 0px 4px rgb(94 169 190 / 10%);
        -webkit-box-shadow: 0 0 0px 4px rgb(94 169 190 / 10%);
        -moz-box-shadow: 0 0 0px 4px rgb(94 169 190 / 10%);
        -ms-box-shadow: 0 0 0px 4px rgb(94 169 190 / 10%);
        -o-shadow: 0 0 0px 4px rgb(94 169 190 / 10%);
    }

@media (max-width: 768px) {
    a, b, body, em, h1, h2, h3, h4, h5, h6, html, i, img, table, li, ol, p, strong, td, th, tr, ul, footer, pre, .card-body {
        font-size: 0.9rem;
    }

    .footer img {
        width: 5rem;
    }

    .jconfirm-title {
        font-size: 1.2rem !important;
    }

    .wTit {
        font-size: 1rem;
    }

    .wsTit {
        font-size: 0.7rem;
    }

    .ezw1, .ezw1_1, .ezw3_1 {
        width: 100%;
    }
}

@media (max-width: 360px) {
    a, b, body, em, h1, h2, h3, h4, h5, h6, html, i, img, table, li, ol, p, strong, td, th, tr, ul, footer, pre {
        font-size: 0.9rem;
    }

    .wTit {
        font-size: 0.5rem;
    }

    .wsTit {
        font-size: 0.3rem;
    }

    .ezw1, .ezw1_1, .ezw3_1 {
        width: 100%;
    }
}

/* @media (max-width: 768px) { */
/* .img-fluid {
        width:100%;
        height:auto;
    } */
/* } */


/* @media (max-width: 999.98px) {
    .img-fluid {
        width:75%;
        height:auto;
    }
} */

/* @media (min-width: 1000px) { */
/* .img-fluid {
        width:80%;
        height:auto;
    } */
/* } */

.nav-pills .nav-link {
    border: thin solid gray;
    margin: .2rem 0;
    border-radius: 0;
}

    /* .nav-link {
    padding: 1rem;
} */

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        background-color: #4f81bd;
    }

@media (max-width: 999.98px) {
    .logo {
        position: absolute;
        top: 0px;
        left: 30px; /* 160px; */
        z-index: 0;
        width: 15%;
    }

    nav.headerBar > div.navbar {
        max-width: 95%
    }
}

@media (min-width: 1000px) and (max-width: 1399.98px) {
    .logo {
        position: absolute;
        top: 0px;
        left: 40px; /* 190px; */
        z-index: 0;
        width: 15%;
    }

    nav.headerBar > div.navbar {
        max-width: 95%
    }
}

@media (min-width: 1400px) {
    .logo {
        position: absolute;
        top: 0px;
        left: 80px; /* 280px; */
        /* 160 */
        z-index: 0;
    }

    nav.headerBar > div.navbar {
        max-width: 90%
    }
}

/* .page_width {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    max-width: 80rem;
} */

.page_width {
    width: 100%;
    margin: 0 auto;
    /* max-width: 75%; */
    /* max-width: 100%; */
}

.headerBar {
    /* padding: 1rem; */
    background-color: rgb(255, 255, 255, 0.7);
    /* opacity: 0.5; */
}

    .headerBar .barList .nav-link {
        color: #5EA9BE !important;
        font-weight: 600;
    }

@media (max-width: 999.98px) {
    .imgLink {
        margin-top: -30px;
    }
}

@media (min-width: 1000px) {
    .imgLink {
        margin-top: -70px;
    }
}


@media (max-width: 799.98px) {
    /* 1199.98px */
    .imgLink div img {
        width: 100px;
        height: auto;
    }
}

/* @media (min-width: 576px) and (max-width: 991.98px) {
    .imgLink div img {
        width:40%;
    }
} */

/* @media (min-width: 992px) and (max-width: 1199.98px) {
    .imgLink div img {
        width:50%;
    }
} */

@media (min-width: 800px) {
    /* 1200px */
    .imgLink div img {
        width: 181px;
        height: auto;
    }
}

@media (max-width: 999.98px) {
    .note {
        width: 100px;
        z-index: 0;
    }
}

@media (min-width: 1000px) and (max-width: 1399.98px) {
    .note {
        width: 150px;
        z-index: 0;
    }
}

@media (min-width: 1400px) {
    .note {
        width: 200px;
        z-index: 0;
    }
}


@media (max-width: 1199.98px) {
    .we-hope-you {
        width: 45%;
        height: auto;
        /* margin-top: 160px; */
    }
}

@media (min-width: 1200px) {
    .we-hope-you {
        width: 45%; /*600px;*/
        height: auto;
        /* margin-top: 200px; */
    }
}

.gradient, .img54321 {
    /*width: 100%; 600px;*/
    /* height: auto; */
    /* margin-top: 150px; */
}

@media (max-width: 799.98px) {
    .register {
        margin-top: -40px;
    }
}

@media (min-width: 800px) and (max-width: 999.98px) {
    .register {
        margin-top: -60px;
    }
}

@media (min-width: 1000px) and (max-width: 1499.98px) {
    .register {
        margin-top: -120px;
    }
}

@media (min-width: 1500px) {
    .register {
        margin-top: -190px;
        /* 270 */
    }
}

.register div img {
    width: 181px;
    height: auto;
}

@media (max-width: 1199.98px) {
    .now-start {
        width: 30%;
        height: auto;
        margin-top: 260px;
        margin-bottom: 80px;
    }
}

@media (min-width: 1200px) {
    .now-start {
        width: 35%;
        height: auto;
        margin-top: 350px;
        margin-bottom: 250px;
    }
}

/* .footer p {
    color: #4F4F4F;
} */


/******************** service ********************/
.servicePlain > .headA, .servicePlain > .headA span {
    display: block;
    text-decoration: none !important;
    cursor: pointer;
}

.servicePlain {
    height: 72px;
    background-color: rgb(203,225,239);
    border-radius: 99px;
    vertical-align: middle;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 0.2rem;
    box-shadow: 10px 10px 4px -7px #00000075;
    -webkit-box-shadow: 10px 10px 4px -7px #00000075;
    -moz-box-shadow: 10px 10px 4px -7px #00000075;
    -ms-box-shadow: 10px 10px 4px -7px #00000075;
    -o-box-shadow: 10px 10px 4px -7px #00000075;
}

    .servicePlain .headA {
        color: #cb7166;
        font-weight: 600;
        font-size: 1.2rem;
        text-indent: 1.0rem;
    }

    .servicePlain .serviceIntro {
        font-size: 1.0rem;
        line-height: 2.0;
        letter-spacing: 0;
        border-left: 2px solid rgb(148,197,214);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 100%;
        cursor: pointer;
    }

.servicePromte {
    background: rgb(228,103,81);
    border-radius: 999px;
    overflow: hidden;
    letter-spacing: 0.2rem;
}

    .servicePromte .promteTitle {
        display: block;
        width: 100%;
        background: rgb(239,164,151);
        color: #cb7166;
        margin: 0;
        line-height: 1.4;
        font-size: 1.8rem;
        font-weight: bold;
    }

    .servicePromte a {
        border-right: 2px solid transparent;
        border-left: 2px solid transparent;
        color: #fff !important;
        line-height: 1.4;
        font-size: 1.8rem;
        font-weight: bold;
        cursor: pointer;
    }

        .servicePromte a:last-of-type {
            border-right: 2px solid transparent;
        }

@media (min-width: 1000px) {
    .servicePlain {
        height: 90px;
    }

        .servicePlain .headA {
            font-size: 2rem;
            text-indent: 1.5rem;
        }

        .servicePlain .serviceIntro {
            font-size: 1.2rem;
            line-height: 3.0;
            padding: 0 2.5rem;
        }

    .servicePromte a {
        border-right: 2px solid rgb(92,115,119);
        border-left: 2px solid transparent;
    }
}

@media (max-width: 999.98px) {
    .servicePlain {
        height: 90px;
    }

        .servicePlain .headA {
            font-size: 1.75rem;
            text-indent: 1.15rem;
        }

        .servicePlain .serviceIntro {
            font-size: 1.15rem;
            line-height: 2.8;
            padding: 0 2.2rem;
        }

    .servicePromte a {
        border-right: 2px solid rgb(92,115,119);
        border-left: 2px solid transparent;
    }

    .servicePromte {
        border-radius: 1.8rem;
    }

        .servicePromte a {
            border-left: none;
            border-right: none;
            border-bottom: 2px solid rgb(92,115,119);
            margin: 0.5rem 0;
            margin: 0.5rem 0;
            padding-bottom: 0.75rem;
            padding-top: 0;
        }

            .servicePromte a:last-of-type {
                border-bottom: none;
            }
}

@media (max-width: 767.98px) {
    .servicePlain {
        height: 90px;
    }

        .servicePlain .headA {
            font-size: 2.1rem;
            text-indent: 1.15rem;
        }

        .servicePlain .serviceIntro {
            font-size: 1.2rem;
            line-height: 2.8;
            padding: 0 2.2rem;
        }
}

@media (max-width: 576px) {
    .servicePlain {
        height: 76px;
    }

        .servicePlain .headA {
            font-size: 1.5rem;
            text-indent: 1.0rem;
        }

        .servicePlain .serviceIntro {
            font-size: 1.2rem;
            line-height: 2.6;
            padding: 0 2.0rem;
        }
}

@media (max-width: 392px) {
    .servicePlain {
        height: 76px;
    }

        .servicePlain .headA {
            font-size: 1.5rem;
            text-indent: 1.0rem;
        }

        .servicePlain .serviceIntro {
            font-size: 1.05rem;
            line-height: 2;
            padding: 0 1.0rem;
        }
}

/******************** about ********************/

.aboutTxt {
    /* margin-top: -570px;
    padding: 0 120px 120px 120px; */
    /*padding: 100px 100px 0 100px;*/
}

    .aboutTxt p {
        font-size: 1.2rem;
    }

@media (max-width: 768px) {
    .aboutTxt p {
        font-size: 1rem;
    }
}

@media (max-width: 360px) {
    .aboutTxt p {
        font-size: 0.9rem;
    }
}

.featureTxt {
    /* margin-top: -50px;
    padding: 0 250px; */
    /*padding: 0 100px;*/
}

@media (max-width: 768px) {
    .featureTxt h1, .programDiv h1, .feeDiv h1, .qaTxt h1 {
        font-size: 1.4rem;
    }

    .qaTxt h5 {
        font-size: 1rem;
    }
}

.programDiv, .feeDiv {
    margin: 0 16rem;
}

@media (max-width: 768px) {
    .programDiv, .feeDiv {
        margin: 0 1rem;
    }
}

.feeDiv, .programDiv, .serviceDiv {
    /* padding: 0 200px; */
    /*padding: 0 100px;*/
}

.table td, .table th {
    vertical-align: middle;
}

.qaTxt {
    /* padding: 0 200px 100px 200px; */
    /*padding: 0 150px 100px 150px;*/
}

/*模擬對角線*/
.out {
    border-top: 40px #D6D3D6 solid; /*上邊框寬度等於表格第一行行高*/
    width: 0px; /*讓容器寬度為0*/
    height: 0px; /*讓容器高度為0*/
    border-left: 130px #BDBABD solid; /*左邊框寬度等於表格第一行第一格寬度*/
    position: relative; /*讓裡面的兩個子容器絕對定位*/
}

b {
    font-style: normal;
    display: block;
    position: absolute;
    top: -40px;
    left: -40px;
    width: 35px;
}

em {
    font-style: normal;
    display: block;
    position: absolute;
    top: -25px;
    left: -70px;
    width: 55x;
}

section:nth-of-type(1) {
    background-image: url(../images/index/main.jpg);
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-height: -webkit-fill-available;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    /* margin-top: 70px; */
}

section:nth-of-type(2) {
    background-image: url(../images/index/gradient.png);
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-height: -webkit-fill-available;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

section:nth-of-type(3) {
    background-image: url(../images/index/05-1.jpg);
    /* 05-1.jpg */
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-height: -webkit-fill-available;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

section:nth-of-type(4) {
    background-image: url(../images/index/04-1.jpg);
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-height: -webkit-fill-available;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

section:nth-of-type(5) {
    background-image: url(../images/index/03-1.jpg);
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-height: -webkit-fill-available;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

section:nth-of-type(6) {
    background-image: url(../images/index/02-1.jpg);
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-height: -webkit-fill-available;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

section:nth-of-type(7) {
    background-image: url(../images/index/01-1.jpg);
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-height: -webkit-fill-available;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

section:nth-of-type(8) {
    /* background-image: url(now_start.png); */
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-height: -webkit-fill-available;
    /* background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat; */
}

#accordion .qTitle {
    color: #a56f27;
}

#atmTable {
    width: 100%;
    margin: 1rem 0 0;
}

    #atmTable td, #atmTable th {
        border: 1px solid black;
        padding: .3rem;
    }

    #atmTable th {
        text-align: center;
        background-color: #f2f2f2;
        width: 12%;
        padding: .7rem 0;
        font-size: 1.2rem;
    }

#atmUl {
    list-style: decimal;
    margin: 0;
    padding: 0 0 0 1.2rem;
}

    #atmUl li {
        margin: 0;
        padding: 0;
        text-align: justify;
        text-justify: auto;
        font-size: 90%;
    }

.borderNone {
    border: none !important;
}

.uploadPicContain {
    position: relative;
    z-index: 10;
    margin: 16px 0;
    display: inline-block;
    height: 0;
}

    .uploadPicContain img {
        max-height: 200px;
        height: auto;
        width: auto;
    }

    .uploadPicContain span.signX {
        position: absolute;
        z-index: 20;
        border-radius: 99px;
        text-align: center;
        display: none;
        top: -9px;
        right: -9px;
        font-size: 12px;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
    }

#mup {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;
}

    #mup input[type="button"] {
        display: inline-block;
    }

.orderDemoPic img {
    max-width: 100%;
    height: 200px;
    width: auto;
    margin-bottom: 18px;
    display: block !important;
}

.orderDemoPic div {
    height: 200px;
    display: block !important;
}
